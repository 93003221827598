import { ReactNode } from "react";

interface IAppContainerProps {
	children: ReactNode;
}

const AppContainer = (props: IAppContainerProps) => {
	const { children } = props;

	return (
		<div className="container mx-auto px-4 py-8 flex gap-4">
			{children}
		</div>
	);
}

export default AppContainer;
