import GridLabel from "./GridLabel";
import Grid, { IGridClickState } from "./Grid";
import IGrid from "../models/Grid";
import ICell from "../models/Cell";

interface IGridPaneProps {
	label: string;
	grid: IGrid;
	onClick: (clickState: IGridClickState) => void;
	onWall: (cell: ICell) => void;
}

const GridPane = (props: IGridPaneProps) => {
	const { label, grid, onClick, onWall } = props;

	return (
		<div className="bg-white shadow-md rounded-lg overflow-hidden flex-auto select-none">
			<GridLabel text={label} />
			<Grid keyPrefix={label} data={grid} onClick={onClick} onWall={onWall} />
		</div>
	);
}

export default GridPane;