import { Slider } from "@nextui-org/react";
import ControlPanelControl from "./ControlPanelControl";

interface IDensitySliderProps {
	value: number;
	onChange: (value: number) => void;
}

const DensitySlider = (props: IDensitySliderProps) => {
	const { value, onChange } = props;

	const handleChange = (newValue: number | number[]) => {
		if (Number.isNaN(newValue)) return;
		onChange(newValue as number);
	}

	return (
		<ControlPanelControl>
			<Slider
				label="Wall Density:"
				step={.1}
				maxValue={1}
				minValue={0}
				value={value}
				onChange={handleChange}
			/>
		</ControlPanelControl>

	)
}

export default DensitySlider;