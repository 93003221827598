import CellState from "../models/CellState";
import GridPane from "./GridPane";
import IGrid, { cloneGrid } from "../models/Grid";
import { IGridClickState } from "./Grid";
import ICell from "../models/Cell";

interface IGridsProps {
	aStar: IGrid;
	dijkstra: IGrid;
	setAStar: (item: IGrid) => void;
	setDijkstra: (item: IGrid) => void;
}

const Grids = (props: IGridsProps) => {
	const { aStar, dijkstra, setAStar, setDijkstra } = props;

	const clearOld = (grid: IGrid) => {
		grid.cells.forEach(x => x
			.filter(y => y.state === CellState.Path || y.state === CellState.Closed || y.state === CellState.Open)
			.forEach(y => y.state = CellState.Empty));
	}

	const updateGrid = (grid: IGrid, state: IGridClickState) => {
		const newGrid = cloneGrid(grid);
		newGrid.start = { ...state.newStart };
		newGrid.end = { ...state.newEnd };
		newGrid.current = { ...state.newStart };
		newGrid.cells[state.oldPos.x][state.oldPos.y].state = CellState.Empty;
		newGrid.cells[state.newPos.x][state.newPos.y].state = state.cellState;

		clearOld(newGrid);

		return newGrid
	}

	const updateGridWithWall = (grid: IGrid, cell: ICell) => {
		const newGrid = cloneGrid(grid);
		const newCell = newGrid.cells[cell.position.x][cell.position.y];
		newCell.state = newCell.state === CellState.Wall ? CellState.Empty : CellState.Wall;
		clearOld(newGrid);

		return newGrid
	}

	const handleGridClick = (state: IGridClickState) => {
		const newAstar = updateGrid(aStar, state);
		setAStar(newAstar);

		const newDijkstra = updateGrid(dijkstra, state);
		setDijkstra(newDijkstra);
	};

	const handleWall = (cell: ICell) => {
		const newAstar = updateGridWithWall(aStar, cell);
		setAStar(newAstar);

		const newDijkstra = updateGridWithWall(dijkstra, cell);
		setDijkstra(newDijkstra);
	}

	return (
		<>
			<GridPane label="A*" grid={aStar} onClick={handleGridClick} onWall={handleWall} />
			<GridPane label="Dijkstra" grid={dijkstra} onClick={handleGridClick} onWall={handleWall} />
		</>
	);
}

export default Grids;
