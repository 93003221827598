import { Button } from "@nextui-org/react";
import ControlPanelControl from "./ControlPanelControl";

interface IStepButtonProps {
	onClick: () => void;
}

const StepButton = (props: IStepButtonProps) => {
	const { onClick } = props;

	return (
		<ControlPanelControl>
			<Button className="w-full" color="warning" onClick={onClick}>Step</Button>
		</ControlPanelControl>
	)
}

export default StepButton;