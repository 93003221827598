import { Dispatch, SetStateAction, useState } from "react";
import ICell from "../models/Cell";
import CellState from "../models/CellState";
import IGrid from "../models/Grid";
import IPosition from "../models/Position";

const getState = (x: number, y: number, size: number) => {
    if (x === 0 && y === 0) return CellState.Start;
    if (x === size - 1 && y === size - 1) return CellState.End;
    return CellState.Empty;
};

const getGrid = (idPrefix: string, size: number) => {
    const cells: ICell[][] = [];

    for (let x = 0; x < size; x++) {
        const row: ICell[] = [];
        cells.push(row);
        for (let y = 0; y < size; y++) {
            row.push({
                id: `${idPrefix}-${x},${y}`,
                position: { x, y },
                state: getState(x, y, size),
            });
        }
    }

    const grid: IGrid = {
        start: { x: 0, y: 0 },
        end: { x: size - 1, y: size - 1 },
        current: { x: 0, y: 0 },
        size: size,
        cells: cells,
    };

    return grid;
};

const useGrid = (
    idPrefix: string,
    size: number
): [
    IGrid,
    Dispatch<SetStateAction<IGrid>>,
    (idPrefix: string, size: number) => IGrid,
    (density: number) => IGrid
] => {
    const [grid, setGrid] = useState<IGrid>(getGrid(idPrefix, size));

    const reset = (idPrefix: string, size: number) => {
        const newGrid = getGrid(idPrefix, size);
        setGrid(newGrid);
        return newGrid;
    };

    const randomize = (density: number) => {
        const newGrid = getGrid(idPrefix, size);

        const start: IPosition = {
            x: Math.floor(Math.random() * size),
            y: Math.floor(Math.random() * size),
        };

        const end: IPosition = {
            x: Math.floor(Math.random() * size),
            y: Math.floor(Math.random() * size),
        };

        newGrid.cells.forEach((x) =>
            x.forEach((y) => {
                y.state =
                    Math.random() > density ? CellState.Empty : CellState.Wall;
            })
        );

        newGrid.cells[start.x][start.y].state = CellState.Start;
        newGrid.cells[end.x][end.y].state = CellState.End;
        newGrid.start = start;
        newGrid.end = end;
        newGrid.current = start;

        setGrid(newGrid);
        return newGrid;
    };

    return [grid, setGrid, reset, randomize];
};

export default useGrid;
