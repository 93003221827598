import React from 'react';
import ReactDOM from 'react-dom/client';
import { NextUIProvider } from "@nextui-org/react";
import './index.css';
import App from './App';
import CssImports from './components/Hidden';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<NextUIProvider>
			<CssImports>
				<App />
			</CssImports>
		</NextUIProvider>
	</React.StrictMode>
);
