import Action from "../models/Action";
import GridCell from "./GridCell";
import ICell from "../models/Cell";
import IGrid from "../models/Grid";
import IPosition from "../models/Position";
import CellState from "../models/CellState";
import { useMemo } from "react";

interface IGridProps {
	keyPrefix: string;
	data: IGrid;
	onClick: (clickState: IGridClickState) => void;
	onWall: (cell: ICell) => void;
}

export interface IGridClickState {
	newStart: IPosition;
	newEnd: IPosition;
	oldPos: IPosition;
	newPos: IPosition;
	cellState: CellState;
}

const Grid = (props: IGridProps) => {
	const { keyPrefix, data, onClick, onWall } = props;

	const classes = useMemo(() => {
		return `grid grid-cols-${data.size} p-4`;
	}, [data.size]);

	const handleClick = (cell: ICell, action: Action) => {
		if (action === Action.Wall) {
			if (cell.position.x === data.start.x && cell.position.y === data.start.y) return;
			onWall(cell);
			return;
		}

		const newStart = action === Action.Start ? cell.position : data.start;
		const newEnd = action === Action.Start ? data.end : cell.position;
		const oldPos = action === Action.Start ? data.start : data.end;

		if (oldPos.x === cell.position.x && oldPos.y === cell.position.y) return;

		onClick({
			newStart: newStart,
			newEnd: newEnd,
			oldPos: oldPos,
			newPos: cell.position,
			cellState: action === Action.Start ? CellState.Start : CellState.End
		})
	}

	return (
		<div className={classes}>
			{data.cells.flatMap(row => row.map(cell => (
				<GridCell key={`[${keyPrefix}-${cell.position.x},${cell.position.y}]`}
					data={cell}
					onClick={handleClick} />
			)))}
		</div>
	);
}

export default Grid;