import ControlPanel from "./components/ControlPanel";
import { useRef, useState } from 'react';
import useGrid from './hooks/useGridHook';
import AppContainer from './components/AppContainer';
import Grids from './components/Grids';
import CellState from "./models/CellState";
import IGrid, { cloneGrid } from "./models/Grid";
import AStar from "./algos/AStar";
import Dijkstra from "./algos/Dijkstra";

const App = () => {
	const [size, setSize] = useState(12);
	const [stepTime, setStepTime] = useState(100);
	const [density, setDensity] = useState(.25);
	const [running, setRunning] = useState(false);
	const [forceStop, setForceStop] = useState(false);

	const forceStopRef = useRef(forceStop);
	forceStopRef.current = forceStop;

	const [aStar, setAStar, resetAStar, randomizeAStar] = useGrid('a*', size);
	const [dijkstra, setDijkstra, resetDijkstra] = useGrid('dijkstra', size);
	const [aStarAlgo, setAStarAlgo] = useState(new AStar(aStar));
	const [dijkstraAlgo, setDijkstraAlgo] = useState(new Dijkstra(dijkstra));


	const doStart = () => {
		const success = handleStep();
		if (success || forceStopRef.current) {
			setRunning(false);
			setForceStop(false);
			forceStopRef.current = false;
			return;
		}
		setTimeout(doStart, stepTime);
	}

	const handleStart = () => {
		setRunning(true);
		setForceStop(false);
		forceStopRef.current = false;
		doStart();
	}

	const handleStop = () => {
		setRunning(false);
		setForceStop(true);
		forceStopRef.current = true;
	}

	const doAStarStep = () => {
		const success = aStarAlgo.step();

		const newGrid = cloneGrid(aStar);

		aStarAlgo.getFScore().forEach((value, key) => {
			if (value === Infinity) return;
			newGrid.cells[key.position.x][key.position.y].fScore = value;
		})

		aStarAlgo.getGScore().forEach((value, key) => {
			if (value === Infinity) return;
			newGrid.cells[key.position.x][key.position.y].gScore = value;
		})

		aStarAlgo.getHScore().forEach((value, key) => {
			if (value === Infinity) return;
			newGrid.cells[key.position.x][key.position.y].hScore = value;
		})

		aStarAlgo.getOpenSet()
			.filter(x => x.state !== CellState.Start && x.state !== CellState.End && x.state !== CellState.Wall)
			.forEach(x => newGrid.cells[x.position.x][x.position.y].state = CellState.Open);

		aStarAlgo.getClosedSet()
			.filter(x => x.state !== CellState.Start && x.state !== CellState.End && x.state !== CellState.Wall)
			.forEach(x => newGrid.cells[x.position.x][x.position.y].state = CellState.Closed);

		aStarAlgo.getPath()
			.filter(x => x.state !== CellState.Start && x.state !== CellState.End && x.state !== CellState.Wall)
			.forEach(x => {
				newGrid.cells[x.position.x][x.position.y].state = CellState.Path;
			})

		setAStar(newGrid);

		return success;
	}

	const doDijkstraStep = () => {
		const success = dijkstraAlgo.step();

		const newGrid = cloneGrid(dijkstra);

		dijkstraAlgo.getFScore().forEach((value, key) => {
			if (value === Infinity) return;
			newGrid.cells[key.position.x][key.position.y].fScore = value;
		})

		dijkstraAlgo.getGScore().forEach((value, key) => {
			if (value === Infinity) return;
			newGrid.cells[key.position.x][key.position.y].gScore = value;
		})

		dijkstraAlgo.getHScore().forEach((value, key) => {
			if (value === Infinity) return;
			newGrid.cells[key.position.x][key.position.y].hScore = value;
		})

		dijkstraAlgo.getOpenSet()
			.filter(x => x.state !== CellState.Start && x.state !== CellState.End && x.state !== CellState.Wall)
			.forEach(x => newGrid.cells[x.position.x][x.position.y].state = CellState.Open);

		dijkstraAlgo.getClosedSet()
			.filter(x => x.state !== CellState.Start && x.state !== CellState.End && x.state !== CellState.Wall)
			.forEach(x => newGrid.cells[x.position.x][x.position.y].state = CellState.Closed);

		dijkstraAlgo.getPath()
			.filter(x => x.state !== CellState.Start && x.state !== CellState.End && x.state !== CellState.Wall)
			.forEach(x => {
				newGrid.cells[x.position.x][x.position.y].state = CellState.Path;
			});

		setDijkstra(newGrid);

		return success;
	}

	const handleStep = () => {
		const aStarSuccess = doAStarStep();
		const dijkstraSuccess = doDijkstraStep();
		return aStarSuccess && dijkstraSuccess;
	}

	const reset = (value?: number) => {
		const newSize = value ?? size;
		const newAStar = resetAStar('a*', newSize);
		const newDijkstra = resetDijkstra('dijkstra', newSize);
		setAStarAlgo(new AStar(newAStar))
		setDijkstraAlgo(new Dijkstra(newDijkstra))
	}

	const handleSizeChange = (value: number) => {
		setSize(value);
		reset(value);
	};

	const handleSetAStar = (grid: IGrid) => {
		setAStar(grid);
		setAStarAlgo(new AStar(grid))
	}

	const handleSetDijkstra = (grid: IGrid) => {
		setDijkstra(grid);
		setDijkstraAlgo(new Dijkstra(grid))
	}

	const handleRandomize = () => {
		const newAStar = randomizeAStar(density);
		const newDijkstra = cloneGrid(newAStar);
		setDijkstra(newDijkstra);
		setAStarAlgo(new AStar(newAStar))
		setDijkstraAlgo(new Dijkstra(newDijkstra))
	}

	return (
		<AppContainer>
			<ControlPanel
				size={size}
				stepTime={stepTime}
				running={running}
				density={density}
				onDensityChanged={setDensity}
				onSizeChange={handleSizeChange}
				onStepTimeChange={setStepTime}
				onRandomize={handleRandomize}
				onStart={handleStart}
				onStop={handleStop}
				onStep={handleStep}
				onReset={reset} />
			<Grids
				aStar={aStar}
				dijkstra={dijkstra}
				setAStar={handleSetAStar}
				setDijkstra={handleSetDijkstra} />
		</AppContainer>
	);
}

export default App;
