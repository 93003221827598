import { Button } from "@nextui-org/react";
import ControlPanelControl from "./ControlPanelControl";

interface IResetButtonProps {
	onClick: () => void;
}

const ResetButton = (props: IResetButtonProps) => {
	const { onClick } = props;

	const handleClick = () => onClick();

	return (
		<ControlPanelControl>
			<Button className="w-full" onClick={handleClick}>Reset</Button>
		</ControlPanelControl>
	)
}

export default ResetButton;