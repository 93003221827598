import { Slider } from "@nextui-org/react";
import ControlPanelControl from "./ControlPanelControl";

interface IStepSliderProps {
	value: number;
	onChange: (value: number) => void;
}

const StepSlider = (props: IStepSliderProps) => {
	const { value, onChange } = props;

	const handleChange = (newValue: number | number[]) => {
		if (Number.isNaN(newValue)) return;
		onChange(newValue as number);
	}

	return (
		<ControlPanelControl>
			<Slider
				label="Step Time (ms):"
				step={1}
				maxValue={1000}
				minValue={1}
				value={value}
				onChange={handleChange}
			/>
		</ControlPanelControl>

	)
}

export default StepSlider;