interface IGridLabelProps {
	text: string;
}

const GridLabel = (props: IGridLabelProps) => {
	const { text } = props;

	return (
		<div className="text-center">
			<span className="h-10 font-semibold">{text}</span>
		</div>
	);
}

export default GridLabel;