import { ReactNode } from "react";

interface IControlPanelControlProps {
	children: ReactNode;
}

const ControlPanelControl = (props: IControlPanelControlProps) => {
	const { children } = props;

	return (
		<div className="p-4 w-48">
			{children}
		</div>
	)
}

export default ControlPanelControl;