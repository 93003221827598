import { Slider } from "@nextui-org/react";
import ControlPanelControl from "./ControlPanelControl";

interface ISizeSliderProps {
	value: number;
	onChange: (value: number) => void;
}

const SizeSlider = (props: ISizeSliderProps) => {
	const { value, onChange } = props;

	const handleChange = (newValue: number | number[]) => {
		if (Number.isNaN(newValue)) return;
		onChange(newValue as number);
	}

	return (
		<ControlPanelControl>
			<Slider
				label="Size:"
				step={12}
				maxValue={36}
				minValue={12}
				value={value}
				onChange={handleChange}
			/>
		</ControlPanelControl>

	)
}

export default SizeSlider;