import { ReactNode, useMemo } from "react";
import { Tooltip } from "@nextui-org/react";

interface IGridCellTooltipProps {
	fScore?: number;
	gScore?: number;
	hScore?: number;
	children: ReactNode;
}

const GridCellTooltip = (props: IGridCellTooltipProps) => {
	const { fScore, gScore, hScore, children } = props;

	const tooltip = useMemo(() => {
		const texts = [];

		if (fScore) texts.push(`FScore: ${fScore}`);
		if (gScore) texts.push(`GScore: ${gScore}`);
		if (hScore) texts.push(`HScore: ${hScore}`);

		return texts.join('\n')
	}, [fScore, gScore, hScore]);

	const showTooltip = useMemo(() => {
		return fScore || gScore || hScore;
	}, [fScore, gScore, hScore]);

	return showTooltip ? (
		<Tooltip
			showArrow
			classNames={{ base: "whitespace-pre-line" }}
			content={tooltip}>
			{children}
		</Tooltip>
	) : <>{children}</>;
}

export default GridCellTooltip;