import { Button } from "@nextui-org/react";
import ControlPanelControl from "./ControlPanelControl";

interface IRandomizeButtonProps {
	onClick: () => void;
}

const RandomizeButton = (props: IRandomizeButtonProps) => {
	const { onClick } = props;

	return (
		<ControlPanelControl>
			<Button className="w-full" color="primary" onClick={onClick}>Randomize</Button>
		</ControlPanelControl>
	)
}

export default RandomizeButton;