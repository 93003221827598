import { ReactNode } from "react";

interface ICssImportsProps {
	children: ReactNode;
}

const CssImports = (props: ICssImportsProps) => {
	const { children } = props;

	return (
		<>
			<div className="hidden">
				<div className='grid-cols-12'></div>
				<div className='grid-cols-24'></div>
				<div className='grid-cols-36'></div>
			</div>
			{children}
		</>
	)
}

export default CssImports;