enum CellState {
	Start,
	End,
	Empty,
	Wall,
	Path,
	Open,
	Closed
}

export default CellState;