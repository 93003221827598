import ICell from "../models/Cell";
import CellState from "../models/CellState";
import IGrid from "../models/Grid";
import IPosition from "../models/Position";

class AStar {
    private openSet: ICell[];
    private closedSet: ICell[];
    private path: ICell[];
    private grid: IGrid;
    private cameFrom: Map<ICell, ICell>;
    private gScore: Map<ICell, number>;
    private fScore: Map<ICell, number>;
    private hScore: Map<ICell, number>;

    constructor(grid: IGrid) {
        this.grid = grid;
        this.openSet = [];
        this.closedSet = [];
        this.path = [];
        this.cameFrom = new Map();
        this.gScore = new Map();
        this.fScore = new Map();
        this.hScore = new Map();

        for (let row of this.grid.cells) {
            for (let cell of row) {
                this.gScore.set(cell, Infinity);
                this.fScore.set(cell, Infinity);
                this.hScore.set(cell, Infinity);
            }
        }

        const startCell = this.grid.cells[this.grid.start.x][this.grid.start.y];
        const hScore = this.heuristic(startCell.position, this.grid.end);
        this.gScore.set(startCell, 0);
        this.fScore.set(startCell, hScore);

        this.openSet.push(startCell);
    }

    getOpenSet(): ICell[] {
        return this.openSet;
    }

    getClosedSet(): ICell[] {
        return this.closedSet;
    }

    getPath(): ICell[] {
        return this.path;
    }

    getGScore(): Map<ICell, number> {
        return this.gScore;
    }

    getFScore(): Map<ICell, number> {
        return this.fScore;
    }

    getHScore(): Map<ICell, number> {
        return this.hScore;
    }

    protected heuristic(position: IPosition, end: IPosition): number {
        return Math.abs(position.x - end.x) + Math.abs(position.y - end.y);
    }

    private getNeighbors(cell: ICell): ICell[] {
        return [
            { x: cell.position.x - 1, y: cell.position.y },
            { x: cell.position.x + 1, y: cell.position.y },
            { x: cell.position.x, y: cell.position.y - 1 },
            { x: cell.position.x, y: cell.position.y + 1 },
        ]
            .filter((pos) => {
                return (
                    pos.x >= 0 &&
                    pos.y >= 0 &&
                    pos.x < this.grid.size &&
                    pos.y < this.grid.size &&
                    this.grid.cells[pos.x][pos.y].state !== CellState.Wall
                );
            })
            .map((pos) => {
                return this.grid.cells[pos.x][pos.y];
            });
    }

    private atEnd(current: ICell): boolean {
        return (
            current.position.x === this.grid.end.x &&
            current.position.y === this.grid.end.y
        );
    }

    private reconstructPath(current: ICell) {
        let temp = current;
        while (this.cameFrom.has(temp)) {
            this.path.push(temp);
            temp = this.cameFrom.get(temp) as ICell;
        }
    }

    private getCurrent(): ICell {
        return this.openSet.reduce((a, b) => {
            const fScoreA = this.fScore.get(a) ?? Infinity;
            const fScoreB = this.fScore.get(b) ?? Infinity;
            return fScoreA < fScoreB ? a : b;
        });
    }

    step(): boolean {
        if (this.openSet.length <= 0) return false;

        let current = this.getCurrent();

        if (this.atEnd(current)) {
            this.reconstructPath(current);
            return true;
        }

        this.openSet = this.openSet.filter((cell) => cell !== current);
        this.closedSet.push(current);

        const neighbors = this.getNeighbors(current).filter(
            (x) => !this.closedSet.includes(x)
        );

        for (let neighbor of neighbors) {
            const tentativeGScore = (this.gScore.get(current) ?? Infinity) + 1;

            if (!this.openSet.includes(neighbor)) {
                this.openSet.push(neighbor);
            } else if (
                tentativeGScore >= (this.gScore.get(neighbor) ?? Infinity)
            ) {
                continue;
            }

            const hScore = this.heuristic(neighbor.position, this.grid.end);

            this.cameFrom.set(neighbor, current);
            this.gScore.set(neighbor, tentativeGScore);
            this.hScore.set(neighbor, hScore);
            this.fScore.set(neighbor, tentativeGScore + hScore);
        }

        return false;
    }
}

export default AStar;
