import ICell from "./Cell";
import IPosition from "./Position";

interface IGrid {
    start: IPosition;
    end: IPosition;
    current: IPosition;
    size: number;
    cells: ICell[][];
}

export const cloneGrid = (grid: IGrid): IGrid => {
    const newCells: ICell[][] = [];
    for (let x = 0; x < grid.size; x++) {
        const row: ICell[] = [];
        for (let y = 0; y < grid.size; y++) {
            const cell = grid.cells[x][y];
            row.push({
				id: cell.id,
                position: { x: cell.position.x, y: cell.position.y },
                state: cell.state,
                fScore: cell.fScore,
                gScore: cell.gScore,
                hScore: cell.hScore,
            });
        }
        newCells.push(row);
    }

    return {
        start: { x: grid.start.x, y: grid.start.y },
        end: { x: grid.end.x, y: grid.end.y },
        current: { x: grid.current.x, y: grid.current.y },
        size: grid.size,
        cells: newCells,
    };
};

export default IGrid;
