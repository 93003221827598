import StartStopButton from "./StartStopButton";
import StepSlider from "./StepSlider";
import StepButton from "./StepButton";
import SizeSlider from "./SizeSlider";
import ResetButton from "./ResetButton";
import RandomizeButton from "./RandomizeButton";
import DensitySlider from "./DensitySlider";

interface IControlPanelProps {
	stepTime: number;
	size: number;
	density: number;
	running?: boolean;
	onDensityChanged: (value: number) => void;
	onSizeChange: (value: number) => void;
	onStepTimeChange: (value: number) => void;
	onRandomize: () => void;
	onStart: () => void;
	onStop: () => void;
	onStep: () => void;
	onReset: () => void;
}

const ControlPanel = (props: IControlPanelProps) => {
	const { size, stepTime, running, density, onDensityChanged, onSizeChange, onRandomize, onStepTimeChange, onStart, onStop, onStep, onReset } = props;

	return (
		<div className="bg-white shadow-md rounded-lg overflow-hidden select-none">
			<SizeSlider value={size} onChange={onSizeChange} />
			<StepSlider value={stepTime} onChange={onStepTimeChange} />
			<DensitySlider value={density} onChange={onDensityChanged} />
			<RandomizeButton onClick={onRandomize} />
			<StartStopButton running={running} onStart={onStart} onStop={onStop} />
			<StepButton onClick={onStep} />
			<ResetButton onClick={onReset} />
			<div className="container p-4">
				<span>LMB - Set Start</span>
				<br />
				<span>Alt+LMB - Set End</span>
				<br />
				<span>Shift+LMB - Set Wall</span>
			</div>
		</div>
	)
}

export default ControlPanel;