import { Button } from "@nextui-org/react";
import ControlPanelControl from "./ControlPanelControl";
import { useMemo } from "react";

interface IStartStopButtonProps {
	running?: boolean;
	onStart: () => void;
	onStop: () => void;
}

const StartStopButton = (props: IStartStopButtonProps) => {
	const { running, onStart, onStop } = props;

	const button = useMemo(() => {
		return running
			? <Button className="w-full" color="danger" onClick={onStop}>Stop</Button>
			: <Button className="w-full" color="success" onClick={onStart}>Start</Button>
	}, [running, onStart, onStop]);

	return (
		<ControlPanelControl>
			{button}
		</ControlPanelControl>
	)
}

export default StartStopButton;