import { useMemo } from "react";
import Action from "../models/Action";
import CellState from "../models/CellState";
import ICell from "../models/Cell";
import GridCellTooltip from "./GridCellTooltip";

interface IGridCellProps {
	data: ICell;
	onClick: (cell: ICell, action: Action) => void;
}

const GridCell = (props: IGridCellProps) => {
	const { data, onClick } = props;

	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (event.button !== 0) return;

		if (!event.altKey && !event.shiftKey) {
			onClick(data, Action.Start);
			return;
		}

		const action = event.altKey ? Action.End : Action.Wall;
		onClick(data, action);
	}

	const className = useMemo(() => {
		const classes = [
			"border-solid",
			"border-1",
			"border-sky-500",
			"aspect-square",
			"text-xs",
			"text-center",
			"content-center"
		];

		if (data.state === CellState.Start) classes.push('bg-green-600');
		if (data.state === CellState.End) classes.push('bg-red-700');
		if (data.state === CellState.Wall) classes.push('bg-stone-600');
		if (data.state === CellState.Path) classes.push('bg-sky-500');
		if (data.state === CellState.Open) classes.push('bg-cyan-300');
		if (data.state === CellState.Closed) classes.push('bg-orange-600');

		return classes.join(' ');
	}, [data.state]);

	return (
		<GridCellTooltip fScore={data.fScore} gScore={data.gScore} hScore={data.hScore}>
			<div className={className} onMouseDown={handleClick}></div>
		</GridCellTooltip>
	);
}

export default GridCell;